import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnPrimary, Button } from '@components/buttons';
import { IcoBan, IcoCheck } from '@components/icons';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { useRouteParams } from '@components/router';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { JSX } from 'preact';
import { useState, useRef } from 'preact/hooks';
import { AssignmentAnswerRow, AssignmentSubmissionStatus } from 'server/types';
import { showError } from '@components/app-error';
import { showToast } from '@components/toaster';
import { validationError } from 'shared/utils';
import { Case } from '@components/conditional';
import { SlideOver } from '@components/slide-over';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { useMinidoc } from '@components/minidoc';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useIntl } from 'shared/intl/use-intl';
import { ManualDom } from '@components/manual-dom';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { UserProfile } from '../guide-course-students/user-profile';
import { showDialog } from '@components/dialog';

type Feedback = Pick<AssignmentAnswerRow, 'status' | 'feedback'>;

const store = rpx.assessments;

type AssignmentResult = RpxResponse<typeof store.getAssignmentResults>;

interface Props {
  close(): void;
  onSubmit(opts: {
    status: AssignmentSubmissionStatus;
    lessonId: string;
    userId: string;
  }): Promise<unknown>;
}

export function AssignmentStatusPill({
  status,
}: {
  status: AssignmentSubmissionStatus | 'not-submitted';
}) {
  return (
    <span
      class={`border capitalize px-1 rounded text-sm ${
        status === 'rejected'
          ? 'border-red-200 bg-red-50 text-red-600'
          : status === 'approved'
          ? 'border-green-200 bg-green-50 text-green-600'
          : 'bg-gray-50'
      }`}
    >
      {status}
    </span>
  );
}

export function AssignmentSlideover(props: Props) {
  const { user, assignment } = useRouteParams();
  const unsaved = useRef(false);
  const { isLoading, data } = useTryAsyncData(
    () =>
      rpx.assessments.getAssignmentResults({
        lessonId: assignment,
        userId: user,
      }),
    [user, assignment],
  );

  return (
    <SlideOver
      close={async () => {
        if (!unsaved.current) {
          return props.close();
        }
        const shouldClose = await showDialog({
          mode: 'warn',
          title: 'You have unsaved feedback.',
          children: `Do you want to close without saving your changes?`,
          confirmButtonText: `Close without saving`,
          cancelButtonText: `Continue editing`,
        });
        if (shouldClose) {
          props.close();
        }
      }}
    >
      {isLoading && <DefaultSpinner />}
      {!isLoading && data?.user && data.results.length > 0 && (
        <AssignmentResults
          questions={data.results}
          lesson={data.lesson}
          user={data.user}
          onSubmit={(data) => props.onSubmit(data).then(() => (unsaved.current = false))}
          onChange={() => (unsaved.current = true)}
        />
      )}
      {!isLoading && !data && <p>No poll results found.</p>}
    </SlideOver>
  );
}

function AssignmentResults(props: {
  user: AssignmentResult['user'];
  questions: AssignmentResult['results'];
  lesson: AssignmentResult['lesson'];
  onSubmit: Props['onSubmit'];
  onChange(): void;
}) {
  const { courseId } = useRouteParams();
  const [feedback, setFeedback] = useState(() =>
    props.questions.map((x) => ({ ...x, isEditable: x.status === 'pending' })),
  );
  const [status, setStatus] = useState(() => {
    return feedback.reduce((acc, q) => {
      if (acc === 'pending' || q.status === 'pending') {
        return 'pending';
      } else if (acc === 'rejected' || q.status === 'rejected') {
        return 'rejected';
      } else {
        return 'approved';
      }
    }, 'approved' as AssignmentSubmissionStatus);
  });

  async function onSubmit() {
    const missing = feedback.filter((x) => x.status === 'pending');
    if (missing && missing.length > 0) {
      const errors = missing.map((m) => ({
        field: m.questionId,
        message: 'You need to review this question',
      }));
      throw validationError(...errors);
    }

    const payload = feedback.map((x) => ({
      questionId: x.questionId,
      feedback: x.feedback,
      status: x.status,
    }));

    try {
      const newStatus = await store.saveAssignmentReviews({
        courseId,
        lessonId: props.lesson.id,
        userId: props.user.id,
        reviews: payload,
      });
      await props.onSubmit({
        lessonId: props.lesson.id,
        userId: props.user.id,
        status: newStatus,
      });
      showToast({
        type: 'ok',
        title: 'Reviews saved',
        message: 'Your reviews have been saved.',
      });
    } catch (err) {
      showError(err);
    }
  }

  return (
    <section class="flex flex-col gap-6">
      <header class="flex items-center gap-2">
        <UserProfile user={props.user} /> ➜ <strong>Assignment Results</strong>
      </header>

      <div class="border rounded-md">
        <header class="border-b p-8 pb-6">
          <span class="opacity-75">{props.lesson.moduleTitle}</span>
          <h3 class="text-lg font-semibold">{props.lesson.title}</h3>
          <AssignmentStatusPill status={status} />
        </header>
        <div class="p-8 py-6">
          <AsyncForm onSubmit={onSubmit}>
            {feedback.map((questions, index) => (
              <SubmissionAnswer
                key={questions.questionId}
                answer={questions}
                studentId={props.user.id}
                disabled={questions.status !== 'pending'}
                index={index}
                onFeedbackChange={(newFeedback) => {
                  props.onChange();
                  setFeedback((state) =>
                    state.map((x) =>
                      x.questionId !== questions.questionId
                        ? x
                        : {
                            ...x,
                            ...newFeedback,
                            isEditable: true,
                          },
                    ),
                  );
                  if (newFeedback.status === 'pending') {
                    setStatus('pending');
                  }
                }}
              />
            ))}
            <footer class="flex flex-col">
              {status === 'pending' && <BtnPrimary>Save & Send feedback</BtnPrimary>}
            </footer>
          </AsyncForm>
        </div>
      </div>
    </section>
  );
}

function SubmissionAnswer({
  answer,
  studentId,
  disabled,
  index,
  onFeedbackChange,
}: {
  answer: AssignmentResult['results'][0] & { isEditable: boolean };
  studentId: UUID;
  disabled: boolean;
  index: number;
  onFeedbackChange: (feedback: Partial<Feedback>) => void;
}) {
  const intl = useIntl();
  const editor = useMinidoc({
    doc: answer.feedback || '',
    middleware: () => [
      placeholder('Type your feedback here...'),
      minidocToolbar([
        ...defaultToolbarActions.filter((a) => a.id !== 'h1'),
        toolbarDropdown({
          id: answer.questionId,
          mediaOnly: true,
          intl,
        }),
      ]),
      cardMiddleware([mediaCard]),
      mediaMiddleware({
        isDownloadable: (fileType) => fileType === 'application/pdf',
      }),
    ],
    onChange: (doc) => {
      onFeedbackChange({ feedback: doc });
    },
  });

  return (
    <div class="mb-12">
      <p class="text-lg whitespace-pre-line mb-4">
        {index + 1}. {answer.questionContent || 'Question'}
      </p>
      <div class="bg-white rounded-lg border">
        <ReadonlyMinidoc
          class="py-4 px-6"
          content={answer.content}
          id={`${studentId}_${answer.questionId}`}
        />
        <div class="flex flex-col px-4 py-6 text-sm border-t bg-gray-50 rounded-b-lg">
          <Case
            when={answer.isEditable}
            fallback={
              answer.feedback && (
                <>
                  <label class="font-bold">Your review:</label>
                  <ReadonlyMinidoc content={answer.feedback} />
                </>
              )
            }
          >
            <div class="border gap-4 px-4 bg-white mb-4">
              <ManualDom el={editor.toolbar.root} />
              <EditorWrapper class="prose dark:prose-invert" editor={editor} />
            </div>
          </Case>

          <FormGroup
            prop={answer.questionId}
            class="flex items-center flex-col gap-6 pt-4 lg:mt-0 justify-center"
          >
            <div class="flex items-center">
              <span class="relative z-0 inline-flex shadow-sm rounded-md">
                <ReviewOption
                  name={`${answer.questionId}.status`}
                  disabled={disabled}
                  checked={answer.status === 'rejected'}
                  type="rejected"
                  onStatusChange={(newStatus) => onFeedbackChange({ status: newStatus })}
                />
                <ReviewOption
                  name={`${answer.questionId}.status`}
                  disabled={disabled}
                  checked={answer.status === 'approved'}
                  type="approved"
                  onStatusChange={(newStatus) => onFeedbackChange({ status: newStatus })}
                />
              </span>
            </div>
            {!answer.isEditable && (
              <footer>
                <Button
                  class="text-indigo-600"
                  onClick={() => onFeedbackChange({ status: 'pending' })}
                >
                  Change Your Feedback
                </Button>
              </footer>
            )}
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

function ReviewOption(
  props: JSX.HTMLAttributes<HTMLInputElement> & {
    checked: boolean;
    type: 'approved' | 'rejected';
    onStatusChange: (newStatus: AssignmentSubmissionStatus) => void;
  },
) {
  const { checked, type, onStatusChange, ...inputProps } = props;
  const isRejectButton = type === 'rejected';
  const bgColor = isRejectButton ? 'bg-red-600' : 'bg-green-600';
  const textColor = isRejectButton ? 'text-red-600' : 'text-green-600';
  const Icon = isRejectButton ? IcoBan : IcoCheck;

  return (
    <label
      class={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 ${
        checked ? `${bgColor} text-white` : 'text-gray-700 opacity-75'
      }
      ${props.disabled ? 'opacity-75 cursor-not-allowed' : 'hover:opacity-100 cursor-pointer'}
      ${isRejectButton ? 'rounded-l-md' : 'rounded-r-md'}`}
    >
      <input
        type="radio"
        checked={checked}
        value={type}
        class="sr-only"
        aria-labelledby="color-choice-0-label"
        {...inputProps}
        onChange={() => onStatusChange(type)}
      />
      <Icon class={`w-4 h-4 mr-2 ${checked ? 'text-white' : textColor}`} />
      {isRejectButton ? 'Reject' : 'Approve'}
    </label>
  );
}
