import { useAuth } from 'client/lib/auth';
import { useEffect } from 'preact/hooks';

type Part = string | undefined;

export function concatDocumentTitle(...parts: Part[]) {
  let result = '';
  for (const part of parts) {
    if (result.length && part) {
      result += ' | ';
    }
    if (part) {
      result += part;
    }
  }
  return result;
}

/*
 * Updates the document title.
 */
export function useDocumentTitle(parts: Part[]) {
  const tenant = useAuth().config?.tenant;

  useEffect(() => {
    // The tenant can be null in certain error condtions,
    // and we still use the document title on error pages,
    // so we need to handle this.
    const currentTenant = tenant || {
      name: '',
    };

    document.title = concatDocumentTitle(...parts, currentTenant.name);

    return () => {
      document.title = currentTenant.name;
    };
  }, [parts, tenant]);
}
