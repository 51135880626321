import { EmptyPage } from '@components/empty-page';
import { GuidePage } from '@components/guide-page';
import { IcoChartBar, IcoDownload } from '@components/icons';
import { RouteProps, router } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import { ComponentChildren } from 'preact';
import { URLS } from 'shared/urls';
import { UserProfile } from '../guide-course-students/user-profile';
import { compactDate } from 'shared/dateutil';
import { BtnSecondary, Button } from '@components/buttons';
import { useMemo, useRef, useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import { PollSlideover } from './poll-slideover';
import { QuizSlideover } from './quiz-slideover';
import { AssignmentSlideover, AssignmentStatusPill } from './assignment-slideover';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { serialAsync } from 'client/utils/serial-async';
import { FilterBox, FilterOptions } from '@components/table/filter-box';
import { AssessmentType, AssignmentSubmissionStatus } from 'server/types';
import { pluralize } from 'shared/formatting';
import { Dropdown, MenuItem } from '@components/dropdown';

type Data = Awaited<ReturnType<typeof load>>;
type Props = RouteProps<Data>;
type StatusFilter = AssignmentSubmissionStatus | 'all';
type TypeFilter = AssessmentType | 'all';

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, data] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.assessments.getGuideAsessmentPage({ courseId }),
  ]);

  return {
    ...data,
    course,
    searchTerm: '',
    status: 'all' as StatusFilter,
    type: 'all' as TypeFilter,
  };
}

function SummaryStat({
  label,
  suffix,
  children,
}: {
  label: ComponentChildren;
  suffix?: string;
  children: ComponentChildren;
}) {
  return (
    <div class="flex flex-row-reverse justify-end gap-2 items-center md:items-start sm:flex-col px-6 p-4 sm:w-1/2 md:w-1/4 border border-t-0 md:border-t border-l-0">
      <label class="text-sm font-medium leading-6 text-gray-500">{label}</label>
      <div>
        <span class="md:text-4xl font-semibold tracking-tight">{children}</span>
        {suffix && <span class="ml-2 font-medium text-sm text-gray-400">{suffix}</span>}
      </div>
    </div>
  );
}

function HCell(props: { class?: string; children: ComponentChildren }) {
  return (
    <div class={`hidden md:table-cell p-4 py-2 whitespace-nowrap border-t ${props.class}`}>
      {props.children}
    </div>
  );
}

function Cell(props: { children: ComponentChildren }) {
  return (
    <span class="hidden first-of-type:table-cell md:table-cell p-4 border-t align-middle">
      {props.children}
    </span>
  );
}

function Page(props: Props) {
  const { state, setState } = props;
  const routeParams = props.route.params;
  const { course, stats, records } = props.state;
  const [isLoading, setIsLoading] = useState(false);
  const terminology = useCurrentTenant().terminology;
  const isEmpty = !stats.quizzesSubmitted && !stats.pollsSubmitted && !stats.assignmentsSubmitted;
  const courseSlug = course.title.toLowerCase().replace(/\s+/g, '-');

  const hideSlideover = () => router.goto(location.pathname);

  const loadMore = async (cursor?: string) => {
    try {
      setIsLoading(true);
      const result = await rpx.assessments.getGuideAssessmentResults({
        courseId: course.id,
        searchTerm: state.searchTerm,
        status: state.status,
        type: state.type,
        cursor,
      });
      setState((s) => ({
        ...s,
        records: cursor ? [...s.records, ...result.records] : result.records,
        cursor: result.cursor,
      }));
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadRef = useRef(loadMore);
  loadRef.current = loadMore;
  const serialLoad = useMemo(() => serialAsync(() => loadRef.current()), []);

  useDidUpdateEffect(serialLoad, [state.searchTerm, state.status, state.type]);

  return (
    <GuidePage course={course} viewLink={URLS.student.course({ course })} type="assessments">
      {routeParams.poll && <PollSlideover close={hideSlideover} />}
      {routeParams.quiz && <QuizSlideover close={hideSlideover} />}
      {routeParams.assignment && (
        <AssignmentSlideover
          close={() => router.goto(location.pathname)}
          onSubmit={async () => {
            // We'll reload the page state so that we get the correct stats
            // and counts and filters.
            const result = await rpx.assessments.getGuideAsessmentPage({
              courseId: state.course.id,
              cursor: state.cursor,
              status: state.status,
              searchTerm: state.searchTerm,
            });
            setState((s) => ({ ...s, ...result }));
            hideSlideover();
          }}
        />
      )}
      {isEmpty && (
        <EmptyPage
          Ico={IcoChartBar}
          description={`No assessments submitted yet — if you haven't already, you can create an assessment in any ${terminology.lesson}. Just click "Assessments" at the bottom of your ${terminology.lesson} editor.`}
          title="No Assessments"
        />
      )}
      {!isEmpty && (
        <section class="flex flex-col gap-8 flex-grow p-8 max-w-7xl mx-auto">
          <header class="flex flex-col sm:flex-row flex-wrap border-t md:border-t-0 border-l">
            <SummaryStat label="Quizzes taken">{stats.quizzesSubmitted}</SummaryStat>
            <SummaryStat label="Polls taken">{stats.pollsSubmitted}</SummaryStat>
            <SummaryStat label="Assignments taken">{stats.assignmentsSubmitted}</SummaryStat>
            <SummaryStat label="Pending Assignments">{stats.assignmentsPending}</SummaryStat>
          </header>

          <div class="flex flex-col gap-4 sm:flex-row sm:justify-between">
            <FilterBox
              searchPlaceholder="Search students"
              compact={false}
              hasFilters={!!state.searchTerm || state.status !== 'all'}
              searchTerm={state.searchTerm}
              setSearchTerm={(searchTerm) => setState((s) => ({ ...s, searchTerm }))}
              clearFilters={() => setState((s) => ({ ...s, searchTerm: '', status: 'all' }))}
            >
              <FilterOptions
                currentValue={state.type}
                options={[
                  { title: 'All', value: 'all' },
                  { title: 'Quiz', value: 'quiz' },
                  { title: 'Poll', value: 'poll' },
                  { title: 'Assignment', value: 'assignment' },
                ]}
                title="Type"
                setFilter={(type: TypeFilter) => setState((s) => ({ ...s, type }))}
              />
              <FilterOptions
                currentValue={state.status}
                options={[
                  { title: 'All', value: 'all' },
                  { title: 'Approved', value: 'approved' },
                  { title: 'Rejected', value: 'rejected' },
                  { title: 'Pending', value: 'pending' },
                ]}
                title="Access"
                setFilter={(status: StatusFilter) => setState((s) => ({ ...s, status }))}
              />
            </FilterBox>

            <div class="flex gap-4">
              {Number(stats.assignmentsPending) > 0 && state.status !== 'pending' && (
                <Button
                  class="hover:bg-gray-200 rounded-lg p-1 px-2"
                  onClick={() => setState((s) => ({ ...s, status: 'pending' }))}
                >
                  {pluralize(
                    `Review ${stats.assignmentsPending} Pending Assignment`,
                    stats.assignmentsPending,
                  )}
                </Button>
              )}
              <Dropdown
                class="mt-4 md:mt-0 hidden md:flex"
                hideDownIcon
                triggerClass="hover:bg-gray-200 rounded-lg p-2"
                renderMenu={() => (
                  <div class="flex flex-col p-2 pb-0">
                    {stats.quizzesSubmitted > 0 && (
                      <MenuItem
                        download={`quizzes-${courseSlug}.csv`}
                        href={`/csv/courses/${course.id}/quizzes.csv`}
                      >
                        Quizzes
                      </MenuItem>
                    )}
                    {stats.pollsSubmitted > 0 && (
                      <MenuItem
                        download={`polls-${courseSlug}.csv`}
                        href={`/csv/courses/${course.id}/polls.csv`}
                      >
                        Polls
                      </MenuItem>
                    )}
                    {stats.assignmentsSubmitted > 0 && (
                      <MenuItem
                        download={`assignments-${courseSlug}.csv`}
                        href={`/csv/courses/${course.id}/assignments.csv`}
                      >
                        Assignments
                      </MenuItem>
                    )}
                  </div>
                )}
              >
                <IcoDownload class="w-4 h-4 mr-1" />
                Download CSV
              </Dropdown>
            </div>
          </div>

          <div class="table table-auto border border-t-0 w-full divide-y">
            <div class="table-row bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <HCell>Student</HCell>
              <HCell>Lesson</HCell>
              <HCell>Submitted At</HCell>
              <HCell>Type</HCell>
              <HCell>Status</HCell>
            </div>
            {records.map((x) => (
              <a
                key={`${x.lessonId}-${x.user.id}`}
                class="table-row text-inherit hover:bg-gray-50 text-sm border-t font-medium"
                href={`?${x.assessmentType}=${x.lessonId}&user=${x.user.id}`}
              >
                <Cell key="student">
                  <span class="flex gap-2 justify-between items-center">
                    <UserProfile user={x.user} />
                    <span class="md:hidden opacity-75">{compactDate(x.completedAt)}</span>
                  </span>

                  <span class="flex gap-2 md:hidden mt-4 justify-between">
                    <span>
                      <span class="capitalize">{x.assessmentType}:</span> {x.lessonTitle}
                    </span>
                    <span>
                      {x.assessmentType === 'quiz' && `${x.quizScore || 0}%`}
                      {x.assessmentType === 'assignment' && (
                        <AssignmentStatusPill status={x.assignmentStatus} />
                      )}
                    </span>
                  </span>
                </Cell>
                <Cell key="lesson">
                  {x.moduleTitle}
                  <span class="opacity-75"> ➜ </span>
                  {x.lessonTitle}
                </Cell>
                <Cell key="completedAt">{compactDate(x.completedAt)}</Cell>
                <Cell key="type">
                  <span class="capitalize">{x.assessmentType}</span>
                </Cell>
                <Cell key="status">
                  {x.assessmentType === 'quiz' && `${x.quizScore || 0}%`}
                  {x.assessmentType === 'assignment' && (
                    <AssignmentStatusPill status={x.assignmentStatus} />
                  )}
                </Cell>
              </a>
            ))}
          </div>

          {!records.length && state.searchTerm && <p class="px-4 opacity-75">No matches found.</p>}

          <footer class="flex justify-center">
            {state.cursor && (
              <BtnSecondary isLoading={isLoading} onClick={() => loadMore(state.cursor)}>
                Load More...
              </BtnSecondary>
            )}
          </footer>
        </section>
      )}
    </GuidePage>
  );
}

router.add({
  url: `manage/courses/:courseId/assessments`,
  load,
  render: Page,
  authLevel: 'guide',
});
