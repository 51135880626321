/**
 * A helper for rendering minidoc into the Preact tree. This wraps the root
 * in a container that has some additional behavior. If you click the container
 * above the minidoc editor, it puts the caret into the editor at the top and
 * ensures the topmost leaf is editable. The same thing for the bottom. So,
 * if the editor only contains a card of some sort (e.g. video / image /
 * whatever), clicking above / below in the wrapper will add the appropriate
 * paragraph above / below.
 */

import { ManualDom } from '../manual-dom';
import { MinidocCore, h as htm } from 'minidoc-editor';

/**
 * Render the specified editor into the VDom, adding extra click behavior to
 * the wrapper to add editable nodes to the top / bottom. See issue #2073 for
 * more context about why this is desirable.
 */
export function EditorWrapper({
  editor,
  class: className,
}: {
  editor: MinidocCore;
  class?: string;
}) {
  return (
    <ManualDom
      el={editor.root}
      class={className}
      onClick={(e: any) => {
        if (e.target !== editor.root && e.target.el !== editor.root) {
          return;
        }

        const makeTypeable = (el: Element | null, pos: 'top' | 'bottom', e: MouseEvent) => {
          const coord = el?.getBoundingClientRect()[pos];
          if (!coord) {
            return;
          }
          const isTargeted =
            (pos === 'top' && coord > e.clientY) || (pos === 'bottom' && coord < e.clientY);
          if (!isTargeted) {
            return;
          }

          if (el.tagName !== 'MINI-CARD') {
            // If the specified element is not a minidoc card, it's already
            // editable (e.g. it's an h1, h2, p, etc).
            return el;
          }

          // The targeted element is a minidoc card, so we'll insert a typable
          // node above / below it.
          const node = htm('p', htm('br'));
          el.insertAdjacentElement(pos === 'top' ? 'beforebegin' : 'afterend', node);
          return node;
        };

        const node =
          makeTypeable(editor.root.firstElementChild, 'top', e) ||
          makeTypeable(editor.root.lastElementChild, 'bottom', e);

        if (node) {
          e.preventDefault();
          const range = document.createRange();
          range.selectNodeContents(node);
          range.collapse(node === editor.root.firstElementChild);

          const sel = document.getSelection()!;
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }}
    />
  );
}
