/**
 * This file contains logic for managing global error state.
 */

import { showDialog } from '@components/dialog';

type AppErrorState =
  | undefined
  | {
      title?: string;
      error: string | Error;
      message?: string;
      data?: { errors?: Error[] };
    };

type ShowArg = Error | AppErrorState;

export const showError = (err: ShowArg) => {
  if (!err) {
    return;
  }
  console.error(err);
  return showDialog({
    mode: 'warn',
    title: (err as AppErrorState)?.title || 'An unknown error occurred',
    children: errMessage(err) || '',
    hideCancel: true,
    confirmButtonText: 'Dismiss',
  });
};

function errMessage(state: ShowArg) {
  if (!state) {
    return;
  }
  // Look for validation errors, and show the first one,
  // if they exist.
  const appErr = state as AppErrorState;
  const errs = appErr?.data?.errors;

  if (!errs?.length) {
    return state.message || (appErr?.error as Error)?.message || appErr?.error?.toString();
  }

  return errs[0].message;
}
